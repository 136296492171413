#subscribe {
  .root {
    position: relative;
    text-align: center;
    height: 570px;
  }
  .parallax-form-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 570px;
    overflow: hidden;
    .parallax {
      background: url($imgAPI-education18) no-repeat;
      background-size: cover;
      height: 220%;
      @include breakpoints-down(xs) {
        background-position: center 0 !important;
        height: 100%;
      }
    }
  }

  .form {
    @include palette-background-paper;
    max-width: 700px;
    border-radius: 20px;
    margin: 0 auto;
    top: 250px;
    z-index: 2;
    width: 100%;
    display: block;
    @include palette-text-secondary;
    position: relative;
    bottom: $spacing10 * -1;
    padding: spacing(5, 2);
    @include breakpoints-up(sm) {
      padding: spacing(5, 10);
    }
    @include breakpoints-down(sm) {
      padding: spacing(4, 2);
      top: 300px;
    }
    h4 {
      @include palette-text-primary;
      margin-bottom: $spacing3
    }
  }

  .field {
    margin: spacing(3, 0, 4)
  }

  .btn.btn-large.button {
    margin: 0 auto;
    min-width: 185px
  }
}