[dir="ltr"] .theme--light #explore .root {
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 20%, $palette-secondary-light 10%);
}

[dir="ltr"] .theme--dark #explore .root {
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 20%, $palette-secondary-dark 10%);
}

[dir="rtl"] .theme--light #explore .root {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 20%, $palette-secondary-light 10%);
}

[dir="rtl"] .theme--dark #explore .root {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 20%, $palette-secondary-dark 10%);
}

#explore {
  .root {
    padding: spacing(10, 0);
    position: relative;
    @include breakpoints-down(sm) {
      background-image: none !important;
    }
    @include breakpoints-down(xs) {
      padding: spacing(5, 0);
    }
    .theme--light & {
      @include bg-color-fade($light-background-paper, 0.7);
    }
     .theme--dark & {
      @include bg-color-fade($dark-background-default, 0.7);
    }
    > * {
      position: relative
    }
  }
  .massonry {
    margin-top: $spacing5
  }
  .parallax-wrap {
    position: absolute;
    @include right(20px);
    left: auto;
    top: 300px;
    height: 500px;
    overflow: visible;
    width: 500px;
    z-index: 0;
  }
  .all-category-card {
    box-shadow: none;
    height: 250px;
    border-radius: 16px;
    margin-bottom: $spacing3;
    overflow: hidden;
    position: relative;
    text-transform: none !important;
    padding: 0 !important;
    background: $palette-secondary-light !important;
    text-align: center;
    display: flex;
    &:hover {
      .property {
        .title-category {
          &:after {
            width: 100%;
          }
        }
        i {
          @include right(-16px);
        }
      }
    }
    @include breakpoints-down(xs) {
      height: 120px;
    }
    .figure {
      margin: 0;
      display: inline-block;
      img {
        width: 100%;
        left: -1px;
        position: relative;
      }
    }
    .property {
      transition: all 0.5s cubic-bezier(.01, 1.17, .59, 1.04);
      @include bg-color-fade($palette-primary-dark, 0.9);
      position: absolute;
      width: 100%;
      height: 100%;
      padding: spacing(2, 6);
      bottom: 0;
      @include text-align(left);
      @include left(0);
      color: $palette-common-white;
      display: flex;
      align-items: center;
      @include breakpoints-down(md) {
        text-align: center;
        bottom: 0;
        i, .desc  {
          display: none
        }
      }
      @include breakpoints-down(xs) {
        padding: spacing(2);
      }
      .desc {
        margin-top: $spacing5;
        font-size: 22px;
        font-weight: $font-regular;
        white-space: normal;
        height: 0;
        opacity: 0;
        display: block;
        overflow: hidden;
      }
      i {
        position: relative;
        font-size: 48px;
        transition: all 0.3s ease-out;
        @include right(0);
        [dir="rtl"] & {
          transform: scale(-1);
        }
      }
      .title-category {
        font-weight: $font-bold;
        font-size: 32px;
        text-transform: uppercase;
        white-space: normal;
        position: relative;
        display: inline-block;
        @include breakpoints-down(xs) {
          font-size: 18px
        }
        &:after {
          content: '';
          height: 4px;
          background: $palette-common-white;
          width: 50%;
          position: absolute;
          bottom: -10px;
          z-index: 0;
          @include left(0%);
          transition: all 0.3s ease-out;
        }
      }
    }
  }
  @mixin fold {
    content: "";
    position: absolute;
    border-radius: 16px;
    border: 1px solid;
    @include palette-divider;
    @include palette-background-paper;
    height: 80px;
    left: 50%;
    transform: translate(-50%);
    @include use-theme(box-shadow, 0 1.5px 12px 2px rgba(0, 0, 0, 0.06), (0px 1px 3px 0px rgba(64, 64, 64, 1), 0px 1px 1px 0px rgba(42, 42, 42, 1), 0px 2px 1px -1px rgba(20, 20, 20, 1)))
  }
  .card-wrap {
    position: relative;
    .fold {
      &:before {
        @include fold;
        width: 80%;
        bottom: -25px;
      }
      &:after {
        @include fold;
        width: 90%;
        bottom: -15px;
      }
    }
  }
}