/*
** RTL Mixins including margin, pading, position, border and border radius
** See documentation https://www.npmjs.com/package/rtl-sass
*/
@import './vendors/rtl-sass/rtl';

/*
** Variables, Functions and Mixins
*/

// Custom variable
@import './utils/variables';

// Custom mixins
@import './utils/mixins';

// Custom functions
@import './utils/functions';

// Custom media queries
@import './utils/media-queries';

// Commons scss class
@import './utils/common';

// Page transition
@import './utils/transition';

// Image API variable
@import './utils/url-img';

// Materialize and Bootstrap overide
@import './utils/overide';

// Materialize extended style
@import './utils/material_extends';

// Bootstrap custom media query for grid
@import './utils/grid';

// Bootstrap additional spacing
@import './utils/spacing';

/*
** Components and Pages
*/

@import './components/about';
@import './components/banner';
@import './components/blog';
@import './components/cards';
@import './components/error';
@import './components/explore';
@import './components/feature';
@import './components/footer';
@import './components/form';
@import './components/header';
@import './components/notification';
@import './components/page-nav';
@import './components/parallax';
@import './components/popular-course';
@import './components/subscribe';
@import './components/testimonials';
@import './components/title';

@import './pages/home';
