$imgAPI-education0: "https://via.placeholder.com/270x320/189a96/FFFFFF";
$imgAPI-education1: "https://via.placeholder.com/270x320/189a96/FFFFFF";
$imgAPI-education2: "https://via.placeholder.com/270x320/189a96/FFFFFF";
$imgAPI-education3: "https://via.placeholder.com/270x320/189a96/FFFFFF";
$imgAPI-education4: "https://via.placeholder.com/270x320/189a96/FFFFFF";
$imgAPI-education5: "https://via.placeholder.com/270x320/189a96/FFFFFF";
$imgAPI-education6: "https://via.placeholder.com/270x320/189a96/FFFFFF";
$imgAPI-education7: "https://via.placeholder.com/270x320/189a96/FFFFFF";
$imgAPI-education8: "https://via.placeholder.com/270x320/189a96/FFFFFF";
$imgAPI-education9: "https://via.placeholder.com/270x320/189a96/FFFFFF";
$imgAPI-education10: "https://via.placeholder.com/364x258/189a96/FFFFFF";
$imgAPI-education11: "https://via.placeholder.com/456x304/36a2c9/FFFFFF";
$imgAPI-education12: "https://via.placeholder.com/237x158/98ad25/FFFFFF";
$imgAPI-education13: "https://via.placeholder.com/317x211/d03c3c/FFFFFF";
$imgAPI-education14: "https://via.placeholder.com/700x462/8e8e8e/FFFFFF";
$imgAPI-education15: "https://via.placeholder.com/700x462/52596b/FFFFFF";
$imgAPI-education16: "https://via.placeholder.com/700x463/8e8e8e/FFFFFF";
$imgAPI-education17: "https://via.placeholder.com/700x463/52596b/FFFFFF";
$imgAPI-education18: "https://i.ibb.co/rc0Z1Gp/parallax-ss05zc.jpg";